@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Aldrich&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aldrich&family=Michroma&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aldrich&family=Michroma&family=Sixtyfour&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aldrich&family=DotGothic16&family=Michroma&family=Sixtyfour&display=swap');

@font-face {
  font-family: "Poppins";
  src: url("../src/fonts/Poppins/Poppins-Regular.ttf");
}

.aldrich-regular {
  font-family: "Aldrich", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.michroma-regular {
  font-family: "Michroma", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.sixtyfour {
  font-family: "Sixtyfour", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "BLED" 0,
    "SCAN" 0;
}

.dotgothic16-regular {
  font-family: "DotGothic16", sans-serif;
  font-weight: 400;
  font-style: normal;
}

/* Gradients */

.bg-black-gradient {
  background: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}

/* Animation */
.sidebar {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes moveUpDown {
  0% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

.moveUpDown-animation {
  animation-name: moveUpDown;
  animation-duration: 4s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.app-wrapper {
  @apply bg-[#000D26] text-white min-h-screen w-screen select-none;
}

.screen-wrapper {
  @apply min-h-[75vh];
}

.btn {
  @apply bg-[#020617] hover:bg-[#0f172a] ring-2 ring-white hover:scale-105 text-white font-bold px-6 py-2 w-fit h-fit 
  rounded-3xl sm:cursor-pointer aldrich-regular;
}

.card {
  @apply bg-gradient-to-r from-purple-700 to-indigo-500 text-white text-[21px] px-6 py-2 rounded-3xl font-bold w-[300px] sm:w-[370px] h-fit mb-6;
}

.card2 {
  @apply ring-1 ring-white text-white text-[21px] px-6 py-2 rounded-3xl font-bold w-[300px] h-fit mb-6;
}

.card3 {
  @apply bg-gradient-to-r from-cyan-700 to-cyan-500 text-white text-[21px] px-6 py-2 rounded-3xl font-bold w-[300px] sm:w-[370px] h-fit mb-6;
}

.card4 {
  @apply text-white text-[21px] px-2 py-2 rounded-3xl font-bold h-fit mb-6;
}

.card5 {
  @apply ring-2 ring-indigo-700 text-white text-[42px] px-6 py-2 rounded-3xl font-bold mx-4 h-fit mb-6;
}

.card6 {
  @apply bg-slate-800 text-white text-[21px] px-3 py-2 rounded-3xl font-bold w-[350px] sm:w-[450px] mx-4 h-fit mb-6;
}

.card7 {
  @apply bg-white text-white text-[21px] px-1 rounded-3xl flex items-center justify-center font-bold py-1;
}

.card8 {
  @apply  ring-2 ring-white text-white text-[21px] px-3 py-2 rounded-3xl font-bold w-[350px] sm:w-[450px] mx-4 h-fit mb-6;
}


.input {
  @apply bg-transparent outline-none border-[3px] border-white p-3 
  rounded-xl mb-4 w-64 text-center;
  color: white;
}

/* Screens */
.card-container {
  @apply flex flex-col justify-center items-center p-2;
}

/* HomeScreen */
.countdown {
  @apply w-[60px] h-[70px] sm:w-[70px] sm:h-[80px] bg-[#020617] text-white flex justify-center items-center rounded-xl;
}

/* Navbar */
.navbar-title {
  @apply aldrich-regular font-bold m-4 hover:text-indigo-400;
}

/* SaleEnds */
.sale-ends-container {
  @apply flex justify-center items-center sm:items-center;
}

/* FaqItem */
.faqitem-card {
  @apply bg-[#041938] hover:scale-105 text-white text-[21px] 
  px-6 py-2 rounded-3xl font-bold w-[300px] sm:w-[550px] h-fit mb-6;
}

/* TokenOrICODetails */
.ico-details-card {
  @apply bg-[#041938] hover:scale-105 text-white text-[21px] 
  px-6 py-2 rounded-3xl font-bold w-[300px] sm:w-[550px] h-fit mb-6;
}

/* Circle */
.circle {
  @apply w-[140px] h-[140px] sm:w-[150px] sm:h-[150px] rounded-full 
  border-[9.5px] sm:border-[10px] flex justify-center items-center 
  hover:ring-8 hover:ring-blue-500 hover:scale-105;
}

.circle-text {
  @apply flex justify-center font-bold font-poppins;
}

.hidden {
  display: none;
}

.display {
  display: block;
}
/* Contact */
.contact-container {
  @apply bg-[#041938] text-white text-[21px] 
  px-6 py-2 rounded-3xl font-bold w-[350px] sm:w-[500px] h-fit mb-6;
}

.contact-input {
  @apply bg-transparent outline-none border-[3px] p-3 
  rounded-xl mt-6 w-[16rem] sm:w-[19rem] text-center appearance-none;
}

/* FaucetScreen */
.faucet-card {
  @apply bg-[#041938] text-white text-[21px] px-6 py-2 rounded-3xl
  font-bold w-[300px] sm:w-[370px] h-fit mb-6;
}

.faucet-text {
  @apply mt-5 text-[1rem];
}

/* Transaction */
.transaction-card {
  @apply bg-[#041938] text-white text-[14px] sm:text-[1rem] px-0 sm:px-1 py-2 rounded-3xl 
  font-bold w-[90vw] sm:w-[600px] h-fit mb-6;
}

/* Transaction Item */
.transaction-item {
  @apply flex pr-0 justify-center items-center text-center;
}

.your-transaction-card {
  @apply bg-[#041938] text-white text-[14px] sm:text-[1rem] px-0 sm:px-1 py-2 rounded-3xl 
  font-bold w-[90vw] h-fit mb-6;
}

/* Header */
.header {
  @apply bg-[#000d26] sticky top-0 ring-1 ring-slate-500 flex justify-between items-center px-3 sm:px-8 
  py-10 h-[60px] z-50;
  backdrop-filter: blur(10px);
}

.gradient-text {
  background: linear-gradient(to right, #8a1bf9, #e606ee); /* Remplacez les couleurs par celles de votre choix */
  -webkit-background-clip: text; /* Permet de remplir le texte avec le dégradé */
  color: transparent;
  @apply aldrich-regular /* Rend le texte transparent pour laisser apparaître le dégradé */
}

.gradient-text2 {
  background: linear-gradient(to right, #029948, #07ca24); /* Remplacez les couleurs par celles de votre choix */
  -webkit-background-clip: text; /* Permet de remplir le texte avec le dégradé */
  color: transparent; /* Rend le texte transparent pour laisser apparaître le dégradé */
}

.gradient-text3 {
  background: linear-gradient(to right, #c59f07, #f3d459); /* Remplacez les couleurs par celles de votre choix */
  -webkit-background-clip: text; /* Permet de remplir le texte avec le dégradé */
  color: transparent;
  @apply aldrich-regular /* Rend le texte transparent pour laisser apparaître le dégradé */
}

.arrow-icon {
  width: 40px;
  height: 40px;
  margin-left: 50px;
  margin-right: 50px;
}

.modal {
  position: fixed; /* Positionnement fixe par rapport à la fenêtre du navigateur */
  top: 50%; /* Centrer verticalement */
  left: 50%; /* Centrer horizontalement */
  transform: translate(-50%, -50%); /* Déplacer le centre de la modal au centre de l'écran */
  z-index: 9999; /* Assurer que la modal est au-dessus de tout le reste */
  background-color: rgb(7, 0, 27); /* Fond blanc */
  padding: 20px; /* Espacement intérieur */
  border-radius: 10px; /* Coins arrondis */
  border-color: rgb(93, 23, 255);
  border-width: 0.5px;
  box-shadow: 0 10px 20px rgba(115, 45, 255, 0.2); /* Ombre légère */
}

.arrow {
  position: absolute;
  z-index: 10;
  top: 850;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.arrow span {
  display: block;
  width: 1.5vw;
  height: 1.5vw;
  border-bottom: 5px solid white;
  border-right: 5px solid white;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
      opacity: 0;
      transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
      opacity: 1;
  }
  100% {
      opacity: 0;
      transform: rotate(45deg) translate(20px, 20px);
  }
}

.radial-bgone {
  position: absolute;
  background: linear-gradient(90deg, #BD24DF 0%, #2D6ADE 97.15%);
  opacity: 0.5;
  filter: blur(392px);
  border-radius: 508.5px;
  width: 51rem;
  height: 50rem;
  right: -13%;
  top: 50%;
}

.radial-bgone2 {
  position: absolute;
  background: linear-gradient(90deg, #BD24DF 0%, #2D6ADE 97.15%);
  opacity: 0.5;
  filter: blur(392px);
  border-radius: 508.5px;
  width: 51rem;
  height: 50rem;
  left: -13%;
}

.barCompleted {
  background-color: rgb(112, 26, 174);
}

.wrapper {
  border: 2px solid rgb(255, 255, 255);
}

.container {
  background-color: rgb(61, 90, 238);
}

.image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120px;
  height: 120px;
  margin:-60px 0 0 -60px;
  -webkit-animation:spin 2s linear infinite;
  -moz-animation:spin 2s linear infinite;
  animation:spin 2s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }